/* This code snippet is written in TypeScript and it defines a Redux slice for managing UTM parameters.
Here's a breakdown of what the code is doing: */
import { getCookie } from '@/lib/utils';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';


interface utmSourceSavedState {
  data: any;  /** Holds the fetched order data or null initially */
  loading: boolean;                /** Indicates whether the fetch operation is in progress */
  error: string | null;            /** Stores any error message, initially set to null */
}

const initialState: utmSourceSavedState = {
  data: "",
  loading: false,
  error: null
};

export const utmSourceSaved = createAsyncThunk(
  'utmSource/fetchUtmSource',  /** Action type identifier */
  async (_data: any, { rejectWithValue }) => {
    try {
      // Retrieve the "__campaign" cookie value
      const campaignCookieRaw = getCookie('__campaign');
      const campaignCookie = campaignCookieRaw ? JSON.parse(campaignCookieRaw) : null;

      if (!campaignCookie) {
        console.warn("No campaign cookie found");
        return;  // No campaign cookie found, so stop execution
      }

      if(Object.values(campaignCookie).length === 0) return;

      const requestData = {
        ..._data,
        ...campaignCookie // Add the cookie value (null if not found)
      };
      // Merge the cookie data into the request body
    
      // gpt get cookies where name "__campaign" and put in body?
      const response = await fetch('/api/v1/post-campaign', {
        method: 'POST',  /** Sends a POST request to the server */
        headers: {
          'Content-Type': 'application/json',  /** Sets the content type to JSON */
        },
        body: JSON.stringify(requestData),
      });

      /** If the request fails, reject the promise with an error */
      if (!response.ok) {
        const errorData = await response.json();
        if(errorData?.error.includes('Blocked by Cloudflare'))
          toast.warn(`${errorData?.error}`)
        return rejectWithValue(errorData);
      }

      /** Parse and return the JSON response data */
      const data = await response.json();
      return data?.results;  /** Return the 'results' field from the response */
    } catch (error: any) {
      /** Handle any unexpected errors (e.g., network issues) */
      return rejectWithValue(error.message);
    }
  }
);



/* This code snippet is creating a Redux slice using the `createSlice` function provided by Redux
Toolkit. Here's a breakdown of what it's doing: */
const utmSourceSlice = createSlice({
  name: 'utm',
  initialState,
  reducers: {

  },
  /** 
  * Handle the lifecycle of the async thunk (pending, fulfilled, rejected) 
  * with extraReducers.
  */
  extraReducers: (builder) => {
    builder
      /**
       * When the utmSourceSaved request is in progress (pending state).
       * This sets loading to true and clears any previous errors.
       */
      .addCase(utmSourceSaved.pending, (state) => {
        state.loading = true;   /** Set loading to true */
        state.error = null;     /** Clear previous error messages */
      })

      /**
       * When the utmSourceSaved request succeeds (fulfilled state).
       * This sets loading to false and stores the fetched data in the state.
       * 
       * @param {PayloadAction<any>} action - The action object containing the response payload.
       */
      .addCase(utmSourceSaved.fulfilled, (state, action: PayloadAction<any>) => {
        state.loading = false;  /** Loading is complete */
        state.data = action.payload;  /** Store the retrieved data */
      })

      /**
       * When the utmSourceSaved request fails (rejected state).
       * This sets loading to false and stores the error message.
       * 
       * @param {PayloadAction<string>} action - The action object containing the error message.
       */
      .addCase(utmSourceSaved.rejected, (state, action) => {
        state.loading = false;  /** Loading is complete */
        state.error = action.error.message || 'Something went wrong';  /** Store the error message */
      });
  },

});

/* The line `export const { setUTMParams, clearUTMParams } = utmSlice.actions;` is exporting the action
creators generated by the `createSlice` function for the Redux slice named `utmSlice`. */
export const { } = utmSourceSlice.actions;

/* `export default utmSlice.reducer;` is exporting the reducer function generated by the `createSlice`
function for the Redux slice named `utmSlice`. This allows other parts of the application to import
and use this reducer to manage the state related to UTM parameters in the Redux store. The reducer
function is responsible for handling actions dispatched to the Redux store and updating the state
accordingly based on the action type. */
export default utmSourceSlice.reducer;
