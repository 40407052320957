// features/deliveryCatalogSlice.ts
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

/**
 * @interface DeliveryCatalogState
 * Represents the structure of the delivery catalog slice state.
 */
interface DeliveryCatalogState {
  data: any | null;  /** The fetched delivery catalog data or null initially */
  loading: boolean;  /** A flag to indicate if the fetch operation is in progress */
  error: string | null;  /** Stores any error message, initially set to null */
  switch : boolean
}

/**
 * The initial state for the delivery catalog slice.
 * @const
 * @type {DeliveryCatalogState}
 */
const initialState: DeliveryCatalogState = {
  data: null,       /** No delivery catalog data initially */
  loading: false,   /** No loading operation in progress initially */
  error: null,      /** No errors initially */
  switch : false
};

/**
 * Async thunk for fetching the delivery catalog data.
 * Sends a POST request with branch_id, latitude, and longitude as parameters.
 * 
 * @param {object} payload - Contains branch_id, latitude, and longitude.
 * @returns {Promise<any>} - The fetched delivery catalog results or an error.
 */
export const fetchDeliveryCatalog = createAsyncThunk(
  'deliveryCatalog/fetchDeliveryCatalog',  /** Action type identifier */
  async (payload: { branch_id: string; latitude: string; longitude: string }) => {
    /** Make a POST request to fetch the delivery catalog */
    const response = await fetch('/api/v1/delivery/delivery-catalog', {
      method: 'POST',  /** The HTTP method used for the request */
      headers: {
        'Content-Type': 'application/json',  /** Set the content type to JSON */
      },
      body: JSON.stringify(payload),  /** Send branch_id, latitude, and longitude in the request body */
    });

    /** If the request fails, throw an error */
    if (!response.ok) {
      const errorData = await response.json();

      if(errorData?.error.includes('Blocked by Cloudflare'))
        toast.warn(`${errorData?.error}`)
      throw new Error('Failed to fetch delivery catalog');

    }

    /** Parse and return the JSON response data */
    const data = await response.json();
    return data?.results;  /** Return the 'results' field from the response */
  }
);

/**
 * Create the delivery catalog slice.
 * Manages the state for loading, successful data retrieval, or error handling.
 */
const deliveryCatalogSlice = createSlice({
  name: 'deliveryCatalog',  /** Name of the slice */
  initialState,             /** Initial state for the slice */

  reducers: {
    // Additional reducers can be added here if needed

    isVegOrNonVeg(state, action: PayloadAction<{ switch: boolean}>) { // ubq veg/non-veg veg filter
      state.switch = action.payload.switch;
    }
  },

  /** 
   * Handle the lifecycle of the async thunk (pending, fulfilled, rejected) 
   * with extraReducers.
   */
  extraReducers: (builder) => {
    builder
      /**
       * When the fetchDeliveryCatalog request is in progress (pending state).
       * This sets loading to true and clears any previous errors.
       */
      .addCase(fetchDeliveryCatalog.pending, (state) => {
        state.loading = true;  /** Set loading to true */
        state.error = null;    /** Clear previous error messages */
      })

      /**
       * When the fetchDeliveryCatalog request succeeds (fulfilled state).
       * This sets loading to false and stores the fetched data in the state.
       * 
       * @param {PayloadAction<any>} action - The action object containing the response payload.
       */
      .addCase(fetchDeliveryCatalog.fulfilled, (state, action: PayloadAction<any>) => {
        state.loading = false;  /** Loading is complete */
        state.data = action.payload;  /** Store the retrieved data */
      })

      /**
       * When the fetchDeliveryCatalog request fails (rejected state).
       * This sets loading to false and stores the error message.
       * 
       * @param {PayloadAction<string>} action - The action object containing the error message.
       */
      .addCase(fetchDeliveryCatalog.rejected, (state, action) => {
        state.loading = false;  /** Loading is complete */
        state.error = action.error.message || 'Something went wrong';  /** Store the error message */
      });
  },
});

/** 
 * Export the deliveryCatalogSlice reducer to be used in the Redux store.
 */

export const { isVegOrNonVeg } = deliveryCatalogSlice.actions;

export default deliveryCatalogSlice.reducer;
