// features/deliveryCatalogSlice.ts
import { UBQ_OrderResponse } from '@/types/next';
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

/**
 * @interface UBQ_Additional
 * Represents the additional details for a UBQ order.
 * This includes the type of order (Delivery or Takeaway), 
 * whether the order is scheduled or instant, and an optional time slot.
 */
export interface UBQ_Additional {
  orderType?: 'Schedule' | 'Instant';  /** Specifies if the order is scheduled or instant */
  type?: 'Instant' | 'Takeaway';      /** Specifies the order type (Instant or Takeaway) */
  timeSlot?: string;                   /** Specifies the selected time slot for the order */
}

/**
 * @interface DeliveryCatalogState
 * Defines the structure of the delivery catalog slice state.
 */
interface DeliveryCatalogState {
  data: UBQ_OrderResponse | null;  /** Holds the fetched order data or null initially */
  loading: boolean;                /** Indicates whether the fetch operation is in progress */
  error: string | null;            /** Stores any error message, initially set to null */
  UBQ_Additional: UBQ_Additional;  /** Additional data about the UBQ order */
}

/** 
 * The initial state for the delivery catalog slice. 
 * @const
 * @type {DeliveryCatalogState}
 */
const initialState: DeliveryCatalogState = {
  data: null,         /** No order data initially */
  loading: false,     /** No loading operation in progress initially */
  error: null,        /** No error initially */
  UBQ_Additional: {
    orderType: 'Instant',  /** The default order type is 'Instant' */
    timeSlot: '',          /** No time slot selected initially */
    type: 'Instant',      /** The default type is 'Delivery' */
  },
};

/**
 * @interface dataShape
 * Represents the shape of the data sent as parameters to the API call.
 */
interface dataShape {
  customer_id: string;  /** The customer ID required to fetch the order data */
  branch_id: string;    /** The branch ID required to fetch the order data */
  transaction_type?: 'Instant' | 'Takeaway'
}

/**
 * Async thunk for fetching the UBQ cart details.
 * Sends a POST request to the server with the customer and branch IDs,
 * and handles success or error states.
 * 
 * @param {dataShape} param0 - An object containing the customer ID and branch ID.
 * @returns {Promise<any>} - The response data or an error.
 */
export const ubqCart = createAsyncThunk(
  'ubqCart/fetchUBQCart',  /** Action type identifier */
  async ({ customer_id, branch_id , transaction_type = "Instant" }: dataShape, { rejectWithValue }) => {
    try {

      const response = await fetch('/api/v1/delivery/get-cart-details', {
        method: 'POST',  /** Sends a POST request to the server */
        headers: {
          'Content-Type': 'application/json',  /** Sets the content type to JSON */
        },
        body: JSON.stringify({
          customer_id,      /** Sends the customer ID */
          branch_id,        /** Sends the branch ID */
          transaction_type : transaction_type == 'Instant' ? 1 : 2  ,
          // transaction_type: 1,  /** Transaction type for the request */ UBQ_Additional
          source: 'Web',        /** Specifies the request source */
          soruce_version: '5.1', /** Version number */
        }),
      });

      /** If the request fails, reject the promise with an error */
      if (!response.ok) {
        const errorData = await response.json();
        if(errorData?.error.includes('Blocked by Cloudflare'))
          toast.warn(`${errorData?.error}`)
        return rejectWithValue(errorData);
      }

      /** Parse and return the JSON response data */
      const data = await response.json();
      return data?.results;  /** Return the 'results' field from the response */
    } catch (error: any) {
      /** Handle any unexpected errors (e.g., network issues) */
      return rejectWithValue(error.message);
    }
  }
);

/**
 * Create the UBQ cart slice.
 * Manages the state for loading, successful data retrieval, or error handling.
 */
const UBQcartSlice = createSlice({
  name: 'ubq_cart',  /** Name of the slice */
  initialState,      /** Initial state for the slice */

  reducers: {
    /**
     * Reducer to collect additional UBQ order details.
     * 
     * @param {DeliveryCatalogState} state - The current state of the delivery catalog slice.
     * @param {PayloadAction<UBQ_Additional>} action - The action containing the additional UBQ order data.
     */
    UBQ_collectData: (state, action: PayloadAction<UBQ_Additional>) => {
      state.UBQ_Additional = action.payload;  /** Update the UBQ_Additional data in the state */
    },
  },

  /** 
   * Handle the lifecycle of the async thunk (pending, fulfilled, rejected) 
   * with extraReducers.
   */
  extraReducers: (builder) => {
    builder
      /**
       * When the ubqCart request is in progress (pending state).
       * This sets loading to true and clears any previous errors.
       */
      .addCase(ubqCart.pending, (state) => {
        state.loading = true;   /** Set loading to true */
        state.error = null;     /** Clear previous error messages */
      })

      /**
       * When the ubqCart request succeeds (fulfilled state).
       * This sets loading to false and stores the fetched data in the state.
       * 
       * @param {PayloadAction<any>} action - The action object containing the response payload.
       */
      .addCase(ubqCart.fulfilled, (state, action: PayloadAction<any>) => {
        state.loading = false;  /** Loading is complete */
        state.data = action.payload;  /** Store the retrieved data */
      })

      /**
       * When the ubqCart request fails (rejected state).
       * This sets loading to false and stores the error message.
       * 
       * @param {PayloadAction<string>} action - The action object containing the error message.
       */
      .addCase(ubqCart.rejected, (state, action) => {
        state.loading = false;  /** Loading is complete */
        state.error = action.error.message || 'Something went wrong';  /** Store the error message */
      });
  },
});

/** 
 * Export the UBQcartSlice reducer to be used in the Redux store.
 */
export const { UBQ_collectData } = UBQcartSlice.actions;
export default UBQcartSlice.reducer;
