// features/deliveryCatalogSlice.ts
import { DeliveryAddressResponse } from '@/types/next';
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

/**
 * @interface DeliveryAddressState
 * Represents the structure of the delivery address slice state.
 */
interface DeliveryAddressState {
  data: DeliveryAddressResponse | null;  /** Holds the fetched delivery address data or null initially */
  loading: boolean;                      /** Indicates whether the fetch operation is in progress */
  error: string | null;                  /** Stores any error message, initially set to null */
}

/** 
 * The initial state for the delivery address slice. 
 * @const
 * @type {DeliveryAddressState}
 */
const initialState: DeliveryAddressState = {
  data: null,        /** Initially no delivery address data */
  loading: false,    /** No loading operation is in progress initially */
  error: null,       /** No error has occurred initially */
};

/**
 * @interface dataShape
 * Represents the shape of the data sent as parameters to the API call.
 */
interface dataShape {
  customer_id: string;  /** The customer ID required to fetch the delivery address */
  branch_id: string;    /** The branch ID required to fetch the delivery address */
}

/**
 * Async thunk for fetching the delivery address data.
 * Makes a POST request to the server with customer and branch IDs, and handles success or error states.
 * 
 * @param {dataShape} param0 - An object containing the customer ID and branch ID.
 * @returns {Promise<DeliveryAddressResponse | void>} - The response data or an error.
 */
export const deliveryAddress = createAsyncThunk(
  'delivery/fetchDeliveryAddress',  /** Action type identifier */
  async ({ customer_id, branch_id }: dataShape, { rejectWithValue }) => {
    try {
      const response = await fetch('/api/v1/delivery/get-address', {
        method: 'POST',  /** Sends a POST request to the server */
        headers: {
          'Content-Type': 'application/json',  /** Sets content type to JSON */
        },
        body: JSON.stringify({  /** Sends customer_id and branch_id in the request body */
          customer_id,
          branch_id,
        }),
      });

      /** 
       * If the response is not OK (status not 2xx), 
       * rejects the promise and returns error data to be handled in the rejected state. 
       */
      if (!response.ok) {
        const errorData = await response.json();
        if(errorData?.error.includes('Blocked by Cloudflare'))
          toast.warn(`${errorData?.error}`)
        return rejectWithValue(errorData);
      }

      /** Parse and return the JSON response data. */
      const data = await response.json();
      return data?.results;  /** Returns the 'results' field from the response */
    } catch (error: any) {
      /** Handle any unexpected errors (e.g., network issues) and pass the error message to the rejected state. */
      return rejectWithValue(error.message);
    }
  }
);

/**
 * Create the delivery address slice.
 * Manages the state for loading, successful data retrieval, or error handling.
 */
const deliveryAddressSlice = createSlice({
  name: 'deliveryAddress',  /** Name of the slice */
  initialState,             /** Initial state for the slice */

  reducers: {
    // Add additional reducers here if necessary
  },

  /** 
   * Handle the lifecycle of the async thunk (pending, fulfilled, rejected) 
   * with extraReducers.
   */
  extraReducers: (builder) => {
    builder
      /**
       * When the deliveryAddress request is in progress (pending state).
       * This sets loading to true and clears any previous errors.
       */
      .addCase(deliveryAddress.pending, (state) => {
        state.loading = true;   /** Set loading to true */
        state.error = null;     /** Clear previous error messages */
      })

      /**
       * When the deliveryAddress request succeeds (fulfilled state).
       * This sets loading to false and stores the fetched data in the state.
       * 
       * @param {PayloadAction<DeliveryAddressResponse>} action - The action object containing the response payload.
       */
      .addCase(deliveryAddress.fulfilled, (state, action: PayloadAction<DeliveryAddressResponse>) => {
        state.loading = false;  /** Loading is complete */
        state.data = action.payload;  /** Store the retrieved data */
      })

      /**
       * When the deliveryAddress request fails (rejected state).
       * This sets loading to false and stores the error message.
       * 
       * @param {PayloadAction<string>} action - The action object containing the error message.
       */
      .addCase(deliveryAddress.rejected, (state, action) => {
        state.loading = false;  /** Loading is complete */
        state.error = action.error.message || 'Something went wrong';  /** Store the error message */
      });
  },
});

/** 
 * Export the deliveryAddressSlice reducer to be used in the Redux store.
 */
export default deliveryAddressSlice.reducer;
