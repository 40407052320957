import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { AppState } from '../store';
import { toast } from 'react-toastify';

interface SeletedBranch {
  branch_id: string,
  branch_name: string,
  city?: string,
  city_code: string,
  city_name?: string,
  latitude: string,
  longitude: string,
  brand_id?: string,
  brand_name?: string,
}

interface Branch {
  branch_id: string;
  branch_name: string;
  branch_address: string;
  branch_alias: string;
  branch_breadcrumb_url: string | null;
  branch_description: string;
  branch_image: string;
  branch_keywords: string;
  branch_logo: string;
  branch_meta_description: string;
  branch_meta_title: string;
  branch_title: string;
  latitude: string;
  longitude: string;
}

// Define the initial state for the deliveryBranches slice
interface DeliveryBranchesState {
  loading: boolean;
  branches: any; // Raw results can be of any type
  error: string | null;
  location: any,
  selectedLoactoin: {
    dumSufar?: SeletedBranch,
    ubq?: SeletedBranch
  },
  nearBy: boolean,
  brandColor: {
    ubq: string;
    dumSafar: string;
  };
}

const initialState: DeliveryBranchesState = {
  loading: false,
  branches: null,
  error: null,
  location: null,
  selectedLoactoin: {},
  nearBy: true,
  brandColor: {
    ubq: '#FF6301|#FFFFFF',
    dumSafar: '#FDB913|#2B3849',
  },
};

// Define the async thunk to fetch the delivery branches data
export const fetchDeliveryBranches = createAsyncThunk(
  'deliveryBranches/fetchDeliveryBranches',
  async ({ lat, long, brand_id }: { lat: number; long: number; brand_id?: number }, { rejectWithValue, dispatch, getState }) => {
    const payload = { lat, long };
    const headers = { "Content-Type": "application/json" };
    try {
      const response = await fetch(`${process.env.NEXT_PUBLIC_SITE_URL}/api/v1/get-all-nearby-branches-v1`, {
        method: 'POST',
        headers,
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        const errorData = await response.json();

        if (errorData?.error.includes('Blocked by Cloudflare'))
          toast.warn(`${errorData?.error}`)
        return rejectWithValue('Failed to fetch branches data');
      }

      const result = await response.json();
      if (Object.entries(result?.results).length > 0) {
        if (result?.results.near_by && (result?.results.near_by).length > 0) {
          //check near by 
          let UBQCounter = 0
          let DumCounter = 0

          for (let outlet of result?.results?.near_by) {
            if (outlet.brand_name == "UBQ" && outlet.brand_id == '1' && (UBQCounter < 1)) {
              dispatch(setUBQ(outlet))
              UBQCounter++
            }
            if (outlet.brand_name == "Dum Safar" && outlet.brand_id == '2' && (DumCounter < 1)) {
              dispatch(setDumSuffar(outlet))
              DumCounter++
            }
          }
        } else {
          if (lat !== 0 && long !== 0) {
            dispatch(nearBy(false))
          }
        }
      }

      return result.results; // Return raw results for the component to process
    } catch (error) {
      console.error('Error fetching branches data:', error);
      return rejectWithValue('Unable to fetch branch data. Please try again later.');
    }
  }
);


// Async thunk to set dropdown location and access state
export const setDropdownLocation = createAsyncThunk<void, { branch: Branch; cityCode: string }, { state: AppState }>(
  'deliveryBranches/setDropdownLocation',
  async ({ branch, cityCode }: { branch: Branch, cityCode: string }, { getState, dispatch }) => {
    const state = getState().deliveryBranches;

    let Ubq, Dum;

    for (let data of state.branches?.brands) {
      if (data.name == 'UBQ') {
        Ubq = data?.data.filter(
          (item: any) => item.city_code == cityCode
        );
      }
      if (data.name == 'Dum Safar') {
        Dum = data?.data.filter(
          (item: any) => item.city_code == cityCode
        );
      }
    }

    const isUbqRoute = window.location.pathname.includes('ubq-delivery');
    const isDumRoute = window.location.pathname.includes('dumsafar-delivery');

    // Dispatch to set UBQ or Dum Safar
    if (isUbqRoute) {
      dispatch(setUBQ({ ...branch, city_code: cityCode }));
    } else if (isDumRoute) {
      dispatch(setDumSuffar({ ...branch, city_code: cityCode }));
    }

    if (isUbqRoute) {
      let dumSafarLoaction = Dum[0]?.branches.filter((item: Branch) => item?.latitude == branch?.latitude && item?.longitude == branch?.longitude)
      if (dumSafarLoaction)
        dispatch(setDumSuffar({ ...dumSafarLoaction[0], city_code: cityCode }));
    }

    if (isDumRoute) {
      let ubqLoaction = Ubq[0]?.branches.filter((item: Branch) => item?.latitude == branch?.latitude && item?.longitude == branch?.longitude)
      if (ubqLoaction)
        dispatch(setUBQ({ ...ubqLoaction[0], city_code: cityCode }));
    }
    localStorage.setItem('location', JSON.stringify({ address: branch?.branch_address, longitude: branch?.longitude, latitude: branch?.latitude }));

  }
);




// Create the deliveryBranches slice
const deliveryBranchesSlice = createSlice({
  name: 'deliveryBranches',
  initialState,
  reducers: {
    saveLoaction(state, action: PayloadAction<any>) {
      state.location = action.payload

      if (window != undefined && action.payload) {
        console.log(action.payload)

        localStorage.setItem('location', JSON.stringify(action.payload))
      }
    },
    setDumSuffar(state, action: PayloadAction<SeletedBranch>) {
      if (!state.selectedLoactoin) {
        state.selectedLoactoin = {};
      }
      state.selectedLoactoin.dumSufar = action.payload;
      localStorage.setItem('dumSafar', JSON.stringify(action.payload))

    },
    setUBQ(state, action: PayloadAction<SeletedBranch>) {
      if (!state.selectedLoactoin) {
        state.selectedLoactoin = {};
      }
      state.selectedLoactoin.ubq = action.payload;
      localStorage.setItem('ubq', JSON.stringify(action.payload))
    },

    nearBy(state, action: PayloadAction<boolean>) {
      state.nearBy = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchDeliveryBranches.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchDeliveryBranches.fulfilled, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.branches = action.payload;

        // // Ensure brandColor is initialized
        // if (!state.brandColor) {
        //   state.brandColor = { ubq: '#FF6301|#FFFFFF', dumSafar: '#FDB913|#2B3849' };
        // }

        // // Safely assign color codes, fallback if not found
        // const ubqBrand = action.payload?.brands?.find((b: any) => b.name === 'UBQ');
        // const dumSafarBrand = action.payload?.brands?.find((b: any) => b.name === 'Dum Safar');

        // state.brandColor.ubq = ubqBrand?.color_code;
        // state.brandColor.dumSafar = dumSafarBrand?.color_code;

        // // Debugging output
        // console.log(state.brandColor.ubq, "=========state.brandColor.ubq");
      })
      .addCase(fetchDeliveryBranches.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload
          ? (action.payload as string)
          : action.error.message || 'An unknown error occurred';
      });
  },
});

export const { saveLoaction, setDumSuffar, setUBQ, nearBy } = deliveryBranchesSlice.actions
export default deliveryBranchesSlice.reducer;
