import { useEffect, useState } from "react";

// Custom hook to detect viewport width
const useViewportWidth = () => {
    // Initialize width with a default value; it will be updated once the component mounts
    const [width, setWidth] = useState<number>(0);

    useEffect(() => {
        // Function to update width
        const handleResize = () => setWidth(window.innerWidth);

        // Set the initial width
        handleResize();

        // Add resize event listener
        window.addEventListener('resize', handleResize);

        // Cleanup event listener on component unmount
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return width;
};

export default useViewportWidth;
